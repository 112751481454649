<template>
  <div class="home">
    <div class="title">考研数学思路养成利器！</div>
    <div class="title-desc">利用提示、分步解析和总结不断刺激解题思路形成</div>

    <div class="free">
      <img @click="goToFree()" class="free-use" src="@/assets/img/home/free-use.png"/>
    </div>

    <div class="books">
      <div class="books-item" v-for="item in books" :key="item.id" @click="goPath(item.url)">
        <img :src="item.bookImgUrl"/>

        <div class="books-item__info">
          <p class="books-item__info-title">{{ item.title }}</p>
          <p class="books-item__info-desc">{{ item.desc }}</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="tool" @click="handleTool('service')">
        <p>联系我们</p>
      </div>
    </div>

    <van-overlay :show="loading">
      <div class="wapper">
        <van-loading type="circular"/>
      </div>
    </van-overlay>

    <van-overlay :show="qrcodeVisible" @click="qrcodeVisible = false">
      <div class="content">
        <div class="block" @click.stop>
          <p class="wechat-desc">关注<span>“无穹解题”</span>内有客服售后、交流分享群</p>
          <img class="qrcode" src="../../assets/img/activity/qrcode.png"/>

          <p class="alert">长按识别二维码</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script type="application/ecmascript">
import AdvanceMethod from '@/assets/img/books/book-gaoshu.png';
import RealMethod from '@/assets/img/books/book-zhenti.png';
import Probability from '@/assets/img/books/book-gailvlun.png';
import XianDai from '@/assets/img/books/book-xiandai.png';
import XianDaiWq from '@/assets/img/books/book-xiandai-wq.png';
import { isWeiXin } from 'assets/js/utils';

export default {
  data () {
    return {
      loading: false,
      qrcodeVisible: false,
      books: [
        {
          id: 'gaoshu',
          bookImgUrl: AdvanceMethod,
          title: '高等数学',
          desc: '高数通用部分的经典题，精编提示和总结，适合基础和强化阶段',
          summary: '共7章，71专题，约600题',
          url: '/guide?name=gaoshushang',
        },
        // {
        //   id: 'zhenti',
        //   bookImgUrl: RealMethod,
        //   title: '十年真题',
        //   desc: '全新的真题解析方式，更清晰、更深刻',
        //   summary: '数学一、二、三独立',
        //   url: '/directory/realQuestion',
        // },
        {
          id: 'xiandai_standard',
          bookImgUrl: XianDai,
          title: '线代基础过关(标准版)',
          desc: '线性代数经典题精选，全面提升解题能力，适合基础/强化阶段',
          summary: '共6章，63专题，无限题',
          url: '/guide?name=xiandai_standard',
        },
        {
          id: 'xiandai',
          bookImgUrl: XianDaiWq,
          title: '线代基础过关(无限版)',
          desc: '线性代数基础知识与运算训练，适合基础巩固',
          summary: '共6章，63专题，无限题',
          url: '/guide?name=xiandai',
        },
        {
          id: 'gailv',
          bookImgUrl: Probability,
          title: '概率论基础过关',
          desc: '概率论经典题精选，全面提升解题能力，适合基础/强化阶段',
          summary: '共30套',
          url: '/guide?name=gailv',
        }
      ]
    }
  },
  methods: {
    goPath (path) {
      this.$router.push(path)
    },
    goToFree () {
      location.href = 'https://free.wq-ky.com'
      // this.$router.push({
      //   path: '/topic_try',
      //   query: {
      //     name: '高数试用',
      //     number: 0,
      //     book: 'gaoshushang',
      //     is_try: true
      //   }
      // });
    },
    handleTool (type) {
      if (type === 'subscription') {
        location.href = 'https://j.youzan.com/wbo51B'
        return
      }
      this.qrcodeVisible = true
    }
  },
  created () {
    this.isLogin = localStorage.access_token;
    if (!isWeiXin() && process.env.NODE_ENV !== 'development' && this.$route.name !== 'main') {
      this.getCode()
    }
  }
}
</script>

<style lang="less" scoped>
.wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home {
  background: #415fd5;
  padding: 0 24px 16px;
  min-height: calc(100vh - 76px);

  .title {
    height: 36px;
    text-align: left;
    font-size: 24px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #ffffff;
    line-height: 36px;
    margin-bottom: 8px;
  }

  .title-desc {
    height: 20px;
    text-align: left;
    font-size: 14px;
    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
    padding-bottom: 9px;
  }

  .books {
    &-item {
      padding: 13px;
      background: #ffffff;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      margin-top: 16px;

      display: flex;
      flex-flow: row;
      justify-content: start;
      align-items: center;

      > img {
        width: 95px;
        height: 110px;
      }

      &__info {
        text-align: left;
        margin-left: 9px;

        &-title {
          height: 24px;
          font-size: 18px;
          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
          font-weight: bold;
          color: #2a3155;
          line-height: 24px;
          margin-bottom: 4px;
        }

        &-desc {
          height: 36px;
          font-size: 12px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #606580;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 12px;
        }

        &-summary {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;

          > p {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #9498aa;
            line-height: 16px;
          }

          > img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

.free {
  display: flex;
  flex-flow: row;
  .free-use {
    width: 100px;
    height: 29px;
  }
}


.footer {
  margin: 20px 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  .tool {
    > p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    background: white;
    padding: 24px;
    border-radius: 4px;

    .alert {
      color: #9498AA;
    }
  }
}
</style>
